import React, { useRef } from "react";
import { isVideoFile } from "../../../helpers/isVideo.ts";
import type { SbAsset } from "../../../types/storyblok";
import useUpdateTransform from "../../../helpers/hooks/useUpdateTransform.ts";

const MediaItemComponent = ({ mediaItems }: { mediaItems: Array<SbAsset> }) => {
    const containerRef = useRef(null);
    const itemsRef = useRef([]);

    useUpdateTransform({
        items: mediaItems,
        containerRef,
        itemsRef
    });

    return (
        <div
            className="w-[150%] ml-[-25%] md:w-[150%] lg:w-full lg:ml-0 h-fit justify-center relative mt-[200px] md:mt-[388px] lg:mt-[311px] xl:mt-[348px]"
            ref={containerRef}
        >
            <div className="absolute top-0 left-0 w-full h-1/2 bg-white z-[-1]" />
            <div className="absolute bottom-0 left-0 w-full h-1/2 bg-black z-[-1]" />
            <div
                className="aspect-video px-6 w-full flex flex-row gap-4 md:gap-6"
                style={{ overflowX: "clip", overflowY: "visible" }}
            >
                {mediaItems.map((item, index) => (
                    <div
                        className="media-item relative h-full w-full"
                        key={item.id}
                        ref={el => (itemsRef.current[index] = el)}
                    >
                        {isVideoFile(item.filename) ? (
                            <video
                                className="w-full h-auto object-cover border-black border-2 rounded-[15px] md:rounded-[40px] overflow-hidden"
                                controls={false}
                                loop
                                autoPlay
                                muted
                            >
                                <source
                                    src={item.filename}
                                    type={`video/${item.filename.split(".").pop()}`}
                                />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img
                                src={item.filename}
                                alt={item.alt}
                                className="aspect-[9/19.5] w-full h-auto object-fit border-black border-2 rounded-[15px] md:rounded-[40px] overflow-hidden"
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MediaItemComponent;
